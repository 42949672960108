import Typography from 'rv-components-onze/src/Typography'
import Button from 'rv-components-onze/src/Button'

import enhancer from './hooks'


const StepCard = ({
    number,
    title,
    description
}) => {
    return (
        <li className="step-card">
            <Typography as="span" variant="subtitle" className="step-card__counter" weight="bold">
                {number}
            </Typography>
            <Typography as="h3" variant="body2" className="step-card__title">
                {title}
            </Typography>
            <Typography as="p" variant="body1" className="step-card__description">
                {description}
            </Typography>
        </li>
    )
}

const Steps = ({ onClickSidebar }) => {
    return (
        <section className="section-steps container">
            <Typography as="h2" variant="headline4" className="section-steps__title">
                3 passos. É o que separa sua empresa e seus colaboradores de um futuro melhor
            </Typography>

            <ul className="section-steps__steps-list">
                <StepCard
                    number='01'
                    title='Escolha um plano ideal'
                    description='Através do Raio X da Onze, criamos um diagnóstico e desenho gratuito do plano ideal para a sua empresa.'
                />

                <StepCard
                    number='02'
                    title='Escolha da grade de fundos'
                    description='Analisando toda a grade da Onze e Icatu, nossos especialistas auxiliam na escolha dos fundos.'
                />

                <StepCard
                    number='03'
                    title='E pronto! Agora é hora de lançar'
                    description='Personalizamos o lançamento e auxiliamos no processo de adesão dos colaboradores.'
                />
            </ul>

            <Button
                label="Contrate agora"
                buttonSize="large"
                buttonStyle="outline"
                iconId='right-arrow'
                iconPosition='right'
                customClass="section-steps__button"
                onClick={onClickSidebar}
            />

        </section>
    )
}

export default enhancer(Steps)