export const cardsList = [
  {
    title: 'Receba um Relatório de Saúde financeira exclusivo ',
    description:
      'Entenda os reais desafios financeiros dos seus colaboradores para acabar com o estresse financeiro na sua empresa.',
  },
  {
    title: 'Antecipe a raíz dos principais problemas do seu time',
    description:
      '68% dos colaboradores têm problemas de saúde mental e física por culpa das preocupações financeiras (Pesquisa Onze, 2023).',
  },
  {
    title: 'Garanta ações de engajamento totalmente personalizadas',
    description:
      'Com os insights exclusivos da nossa plataforma, receba um calendário de palestras in company customizado com as necessidades do seu time.',
  },
]
