import Typography from 'rv-components-onze/src/Typography'
import Button from 'rv-components-onze/src/Button'

import Card from '../CardMissingPush'
import { cardsList } from './mock'

import Routes from 'Routes'

const { financialHealth } = Routes

const SectionMissingPush = () => {
  return (
    <section className="section-missing-push">
      <div className="container section-missing-push__content">
        <div className="section-missing-push__header">
          <Typography
            as="h2"
            variant="headline5"
            className="section-missing-push__title">
            Seu RH <span className="section-missing-push__data-driven"> data driven </span> com um benefício de alto impacto
          </Typography>
          <Typography
            as="p"
            variant="body1"
            className="section-missing-push__subtitle">
            A previdência corporativa da sua empresa pode ter um impacto ainda maior com a nossa solução de Saúde Financeira:
          </Typography>
        </div>
        <div className="section-missing-push__body">
          {cardsList.map(({ title, description }, index) => (
            <Card key={`card-${index}`} title={title}>
              {description}
            </Card>
          ))}
        </div>
        <Button
          as="a"
          href={financialHealth.path}
          label="Conheça nosso hub de Saúde Financeira"
          iconId="right-arrow"
          iconPosition="right"
          buttonStyle="outline"
          customClass="section-missing-push__button section-financial-health__button"
        />
      </div>
    </section>
  )
}

export default SectionMissingPush


