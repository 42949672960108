import Typography from 'rv-components-onze/src/Typography'
import Button from 'rv-components-onze/src/Button'
import Img from 'components/Img'

import Routes from 'Routes'

const { management } = Routes

const OurWallet = () => {
  return (
    <section className="home-our-wallet" data-show>
      <figure className="home-our-wallet__image-wrapper">
        <Img
          className="home-our-wallet__image"
          src="/img-logos-home.png"
          alt="Imagem ilustrativa de um grafico de pizza"
        />
      </figure>

      <div className="home-our-wallet__description">
        <Typography
          as="h2"
          variant="headline5"
          className="home-our-wallet__title">
          O portfólio de previdência mais completo do Brasil
        </Typography>

        <Typography as="h2" variant="body1" className="home-our-wallet__text">
          Tenha à sua disposição, mais de 300 fundos de investimentos de uma das maiores gestoras do país. 
          Uma carteira robusta para quem busca rentabilidade, proteção e eficiência na hora de construir patrimônio.
        </Typography>

        <Button
          as="a"
          href={management.path}
          label="Saiba mais"
          iconId="right-arrow"
          iconPosition="right"
          buttonStyle="text"
          customClass="home-our-wallet__button"
        />
      </div>
    </section>
  )
}

export default OurWallet
