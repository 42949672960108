import Typography from 'rv-components-onze/src/Typography'
import Button from 'rv-components-onze/src/Button'
import Icon from 'rv-components-onze/src/Icon'
import Img from 'components/Img'
import Staw from 'staw'

import { isMobile } from 'utils/getDevice'
import enhancer from './hooks'

const sliderImages = [
  {
    url: '',
  },
  {
    url: '',
  },
  {
    url: '',
  },
  {
    url: '',
  },
  {
    url: '',
  },
  {
    url: '',
  },
]

const Hero = ({ onClickSidebar }) => {
  return (
    <section className="hero-company">
      <div className="container">
        <div className="hero-company__header">
          <div className="hero-company__content">
            <Typography
              as="h2"
              variant="headline4"
              className="hero-company__title"
              weight="bold">
              Muito mais do que uma Previdência Corporativa
            </Typography>

            <Typography
              as="p"
              variant="body1"
              className="hero-company__main-text">
              Uma solução completa para conquistar saúde financeira a longo prazo com um plano de previdência feito sob medida para a sua empresa.
            </Typography>

            <Button
              label="Agendar demonstração agora"
              iconId="right-arrow"
              iconPosition="right"
              customClass="hero-company__button"
              buttonStyle="outline"
              onClick={onClickSidebar}
            />
          </div>
          <div className="hero-company__image">
            <Img
              className="hero-company__image-device"
              src="/rebranding-sua-empresa-img2.png"
              alt="device"
            />
          </div>
        </div>

        {/* <div className="hero-company__slider-content slider">
          <div className="slider__text">
            <div className="slider__title-content">
              <Icon iconId="grafismo-onze-company" />
              <Typography as="h3" variant="headline5" className="slider__title">
                Lorem ipsum dolor sit amet
              </Typography>
            </div>
            <Typography as="body1" className="slider__description">
              Tum dicere exorsus est eligendi optio, cumque nihil ut labore et
              negent satis.
            </Typography>
          </div>
          <div className="slider__staw">
            <Staw
              hasArrows={false}
              hasDots={true}
              visibleGutter={isMobile() ? 20 : 100}
              slidesPerView="auto">
              {sliderImages.map((e, idx) => (
                <div key={idx} className="slider__image" />
              ))}
            </Staw>
          </div>
        </div> */}
      </div>
    </section>
  )
}

export default enhancer(Hero)
